import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { colors as defaultColors } from 'tokens';
import addBindTo from '../Provider/hocs/addBindTo';
import Button from '../Button/Button';
import InputWrapper from './InputWrapper';

const Input = ({
  className,
  inputClassName,
  label,
  id,
  type,
  pattern,
  name,
  maxLength,
  onChange,
  onBlur,
  value,
  error: propsError,
  success,
  required,
  disabled,
  onRedirectUrl,
  icon,
  onClick,
  theme,
  placeholder,
  touched,
  leadingIcon,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const error = touched ? propsError : null;
  function handlePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <InputWrapper
      className={clsx('input', className)}
      label={label}
      placeholder={placeholder}
      value={value}
      type={type}
      error={error}
      icon={icon}
      disabled={disabled}
      theme={theme}
    >
      <div className="relative rounded-md shadow-sm">
        <input
          className={clsx(
            'input__field',
            inputClassName,
            (leadingIcon || icon) && 'block w-full !pr-8 border-gray-300 rounded-md',
          )}
          id={id}
          type={type === 'password' && showPassword ? 'text' : type}
          pattern={pattern}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value === null || value === undefined ? '' : value}
          maxLength={maxLength}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          {...rest}
        />

        {leadingIcon && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {leadingIcon}
          </div>
        )}
        {icon && (
          <Button
            as={onRedirectUrl ? 'a' : 'button'}
            href={onRedirectUrl}
            onClick={onClick}
            appearance="simple"
            target="_blank"
            className={`input__icon ${icon}`}
            rel="noopener noreferrer"
            size="tiny"
            aria-label={onRedirectUrl}
          />
        )}
      </div>

      <label className="input__label" htmlFor={id}>
        {label}
      </label>
      {type === 'password' && (
        <button
          onClick={() => handlePasswordVisibility()}
          className={
            showPassword
              ? 'input__icon-password icon-eye-off-fill'
              : 'input__icon-password icon-eye-fill'
          }
          type="button"
          aria-label="visibility"
        />
      )}
      {error && <span className="input__error">{error}</span>}
      {success && <span className="input__success">{success}</span>}
    </InputWrapper>
  );
};

Input.defaultProps = {
  id: '',
  className: '',
  inputClassName: null,
  value: '',
  label: '',
  placeholder: '',
  type: 'text',
  pattern: '',
  name: '',
  maxLength: null,
  error: '',
  success: '',
  required: false,
  disabled: false,
  touched: true,
  onChange: () => {},
  onBlur: () => {},
  icon: '',
  leadingIcon: null,
  onRedirectUrl: '',
  onClick: () => {},
  theme: { colors: defaultColors },
};

Input.propTypes = {
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  success: PropTypes.string,
  touched: PropTypes.bool,
  required: PropTypes.bool,
  icon: PropTypes.string,
  leadingIcon: PropTypes.node,
  onRedirectUrl: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Input);
