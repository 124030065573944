import styled, { css } from 'styled-components';
import { devices } from 'tokens';

const textSize = {
  p1: css`
    font-size: 14px;
    line-height: 20px;

    line-height: 21px;

    color: ${({ disabled, hint, theme }) => (disabled || hint) && `${theme.colors.grey400}`};
    font-size: ${({ accent, alternative }) => (accent || alternative) && `16px`};
    color: ${({ accent, theme }) => accent && `${theme.colors.primary}`};
    color: ${({ alternative, theme }) => alternative && `${theme.colors.white}`};

    @media ${devices.mobile}, ${devices.xsmall} {
      font-size: 12px;
      line-height: 18px;
    }
  `,

  p2: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.text500};

    color: ${({ color, theme }) => color && `${theme.colors[`${color}500`]}`};
  `,
};

const TextWrapper = styled.p`
  font-family: var(--secondary-font);
  ${({ size }) => textSize[size]};
  white-space: pre-wrap;
`;

export default TextWrapper;
