/* eslint-disable indent */
import styled from 'styled-components';

const InputWrapper = styled.div`
  font-family: var(--primary-font);
  height: auto;
  width: auto;
  height: 40px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  opacity: 0.8;
  height: auto;

  .input {
    &__label {
      font-size: 15px;
      position: absolute;
      pointer-events: none;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.grey700};
      left: 12px;
      top: 20px;
      transform: translateY(-50%);
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      padding: 0 5px 0 3px;
      ${({ value, theme, placeholder }) =>
        (value || value !== null || placeholder) &&
        `
        top: 0px;
        background: ${theme.colors.white};
        color: ${theme.colors.text200};
        font-size: 12px;
        line-height: 12px;
        padding: 0 5px 0 3px;
        background: linear-gradient(0deg, rgba(255,255,255, 0) 40%, rgba(255,255,255, 1) 40%);
      `}

      /* STATE : DISABLED */
      background: ${({ disabled }) =>
        disabled && `linear-gradient(0deg, rgba(255, 255, 255, 0) 40%, rgba(255,255,255, 1) 40%);`};
      color: ${({ disabled, theme }) => disabled && `${theme.colors.grey700}`};

      /* VALUE + ERROR */
      color: ${({ value, error, theme }) => value && error && `${theme.colors.danger500}`};

      ${({ type, theme }) =>
        type === 'number' &&
        `
          color: ${theme.colors.grey600};
      `}
    }

    &__field {
      font-family: var(--primary-font);
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: block;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.grey500};
      height: 40px;
      width: 100%;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.text500};
      padding: 0 15px;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      box-shadow: none;

      border-color: ${({ error, theme }) => error && `${theme.colors.danger500}`};

      /* STATE : DISABLED */
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey200}`};
      color: ${({ disabled, theme }) => disabled && `${theme.colors.grey700}`};

      ::placeholder {
        color: ${({ theme }) => theme.colors.text200};
        font-size: 15px;
      }

      &::-webkit-inner-spin-button {
        height: 30px;
        position: relative;
        top: 10px;
      }

      :focus {
        border-width: 1px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.info500};

        /* State: 'error' */
        border-color: ${({ error, theme }) => error && `${theme.colors.danger500}`};
        color: ${({ error, theme }) => error && `${theme.colors.danger500}`};
      }

      :focus + .input__label,
      :-webkit-autofill + .input__label {
        top: 0;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.info500};
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 40%);
        padding: 0 5px 0 3px;

        /* State: 'error' */
        color: ${({ error, theme }) => error && `${theme.colors.danger500}`};
      }

      ${({ type }) =>
        type === 'number' &&
        `
          ::-webkit-outer-spin-button,
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
          font-size: 14px;
          ${({ disabled }) => !disabled && 'background-color: transparent;'}
      `}
    }

    &__icon {
      position: absolute;
      right: 10px;
      top: 7px;
      color: ${({ theme }) => theme.colors.grey600};
      font-size: 24px;
    }

    &__icon-password {
      position: absolute;
      right: 8px;
      top: 7px;
      color: ${({ theme }) => theme.colors.grey700};
      font-size: 24px;
    }

    &__error {
      margin: 8px 16px 0;
      color: ${({ theme }) => theme.colors.danger500};
      font-size: 12px;
    }

    &__success {
      margin: 8px 16px 0;
      color: ${({ theme }) => theme.colors.success500};
      font-size: 12px;
    }
  }
`;

export default InputWrapper;
