// eslint-disable-next-line import/no-unresolved
import 'runtime';
import React, { useState, useEffect } from 'react';
import { setCookie, parseCookies } from 'nookies';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import ConsentBannerWrapper from './ConsentBannerWrapper';

/** @namespace ConsentBanner */
const ConsentBanner = ({ t }) => {
  const [displayCookies, setDisplayCookies] = useState(false);

  useEffect(() => {
    const { accepted } = parseCookies('accepted');
    setDisplayCookies(!accepted);
  }, []);

  const handleClick = () => {
    setCookie(null, 'accepted', true, {
      path: '/',
      secure: true,
    });
    setDisplayCookies(false);
  };

  return (
    displayCookies && (
      <ConsentBannerWrapper role="alert">
        <div className="consent-banner" data-cy="consent-cookies">
          <Text>{t('consent-banner-text')}</Text>
          {/* this block to add link and title */}
          {/* <a href='/'>{linkTitle}</a> */}
          <Button data-cy="consent-cookies-btn" appareance="simple" onClick={() => handleClick()}>
            {t('consent-banner-button')}
          </Button>
        </div>
      </ConsentBannerWrapper>
    )
  );
};

ConsentBanner.propTypes = {
  t: PropTypes.func.isRequired,
};

ConsentBanner.getInitialProps = async () => ({
  namespacesRequired: ['consentBanner'],
});

export default withTranslation('consentBanner')(ConsentBanner);
