import React from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import { BsInfoCircleFill } from 'react-icons/bs';
import clsx from 'clsx';

import Tooltip from 'components/Tooltip/Tooltip';

import addBindTo from '../Provider/hocs/addBindTo';
import TitleWrapper from './TitleWrapper';

const heading = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const Title = ({ className, children, size, theme, title, tooltip }) => {
  const WrapperTag = heading.includes(size) ? size : 'h1';

  return (
    <TitleWrapper
      className="title whitespace-pre-line"
      size={size}
      as={WrapperTag}
      theme={theme}
      title={title}
    >
      <div className={clsx('flex gap-2 items-center', className)}>
        {children}
        {tooltip && (
          <Tooltip text={tooltip}>
            <BsInfoCircleFill className="text-primary-500 cursor-pointer text-sm" />
          </Tooltip>
        )}
      </div>
    </TitleWrapper>
  );
};

Title.defaultProps = {
  className: '',
  children: '',
  size: 'h1',
  title: '',
  tooltip: null,
  theme: { colors: defaultColors },
};

Title.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 's1', 's2']),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Title);
