import React from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import addBindTo from '../Provider/hocs/addBindTo';
import TextWrapper from './TextWrapper';

const Text = ({ className, children, size, disabled, color, theme, parseUris, style }) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const doUriParse = (body) => {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: body.replace(
          urlRegex,
          '<a href="$1" target="_blank" class="transition:colors underline hover:cursor-pointer hover:text-primary-500">$1</a>',
        ),
      },
    });
  };

  return (
    <TextWrapper
      className={`text ${className}`}
      size={size}
      disabled={disabled}
      color={color}
      theme={theme}
      style={style}
    >
      {parseUris ? doUriParse(children) : children}
    </TextWrapper>
  );
};

Text.defaultProps = {
  className: '',
  size: 'p1',
  disabled: false,
  color: '',
  theme: { colors: defaultColors },
  children: '',
  parseUris: false,
  style: null,
};

Text.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['p1', 'p2']),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.shape(PropTypes.any),
  color: PropTypes.oneOf(['primary', 'warning', 'info', 'success', 'danger', '']),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  parseUris: PropTypes.bool,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Text);
