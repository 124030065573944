/* eslint-disable prettier/prettier, indent */
import styled, { css } from 'styled-components';
import { devices } from 'tokens';

const appearances = {
  default: css`
    width: 18rem;

    .select--input {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .option__icon {
      height: 100%;
    }

    .select {
      &__input {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        padding: 0 1rem;
        border-color: ${({ error, theme }) => error && `${theme.colors.danger500}`};

        .button__content {
          width: 100%;
          justify-content: space-between;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      &__error {
        margin: 8px 16px 0;
        color: ${({ theme }) => theme.colors.danger500};
        font-size: 12px;
      }

      &__list {
        width: 100%;

        .option__count {
          color: ${({ theme }) => theme.colors.grey600};
          font-style: italic;
        }
      }
    }
  `,

  lang: css`
    width: 70px;

    .select {
      &__input {
        width: 80px;
        border: none;
        background-color: transparent;

        &--display {
          width: 100%;
          justify-content: flex-start;
          color: ${({ theme }) => theme.colors.text500};
          font-size: 12px;
          padding: 0 0 0 20px;

          ${({ displayedIcon }) =>
            displayedIcon &&
            `
          background-image: url(${displayedIcon});
          background-size: 16px;
          background-repeat: no-repeat;
          background-position-y: center;
        `}
        }

        i {
          font-size: 20px;
          color: ${({ theme }) => theme.colors.text500};
        }
      }

      &__list {
        width: max-content;
        right: 0;
        ${({ top }) => (top ? 'bottom: 3rem;' : 'top: 2.5rem;')};

        @media ${devices.mobile}, ${devices.xsmall} {
          left: 0;
        }

        &--option {
          .option__icon {
            width: 20px;
            margin: 0 8px 0 0;
          }

          :hover {
            background-color: ${({ theme }) => theme.colors.grey200};
            color: ${({ theme }) => theme.colors.grey900};
          }
        }
      }
    }
  `,

  date: css`
    width: 300px;

    .select--input {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .select {
      &__input {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        padding: 0 16px;

        .button__content {
          width: 100%;
          justify-content: space-between;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      &__error {
        margin: 8px 16px 0;
        color: ${({ theme }) => theme.colors.danger500};
        font-size: 12px;
      }

      &__list {
        width: 100%;
      }

      &__date-header {
        display: flex;
        flex-direction: column;
        padding: 15px;
        border-bottom: solid 1px ${({ theme }) => theme.colors.grey500};
        &-subtitle {
          width: 100%;
        }
      }

      &__date-footer {
        border-top: solid 1px ${({ theme }) => theme.colors.grey500};
      }

      &__date-customize-btn {
        border-radius: 0px;
        padding: 16px;
        font-weight: 400;
        width: 100%;
        justify-content: flex-start;
        background-color: ${({ isCustomSelected, theme }) =>
          isCustomSelected ? 'var(--primary-500)' : theme.colors.white};
        color: ${({ isCustomSelected, theme }) =>
          isCustomSelected ? theme.colors.white : theme.colors.grey900};

        :hover {
          background-color: var(--primary-500);
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  `,

  sort: css`
    width: 200px;
    display: flex;
    justify-content: flex-end;

    .select {
      &__input {
        padding: 0;
        border: none;
        background-color: transparent;

        &--display {
          color: ${({ theme }) => theme.colors.text500};
          display: flex;
          justify-content: flex-end;
        }

        i {
          font-size: 24px;
        }
      }
    }
  `,
};

const SelectWrapper = styled.div`
  font-family: var(--primary-font);
  font-size: 14px;
  position: relative;
  display: flex;

  .select--label {
    position: absolute;
    top: -12px;
    left: 16px;
    padding: 0 4px;
    color: ${({ theme }) => theme.colors.grey500};
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    background-color: white;
  }

  .select {
    &__input {
      display: flex;
      align-items: center;
      height: 40px;

      &--display {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.grey700};

        background-color: ${({ disabled, theme }) =>
          disabled && `${theme.colors.grey300}`}; /* DISABLED */

        :hover {
          background-color: ${({ disabled, theme }) =>
            disabled && `${theme.colors.grey300}`}; /* DISABLED */
        }
      }
    }

    &__list {
      ${({ overrideOverflow }) =>
        !overrideOverflow &&
        `max-height: 16rem;
      overflow-y: auto;
      `}
      opacity: ${({ open }) => (open ? '1' : '0')};
      pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
      position: absolute;
      z-index: 99;
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.grey500};
      border-radius: 4px;
      margin: 10px 0 0;
      ${({ top }) => (top ? 'bottom: 3rem;' : 'top: 2.5rem;')};
    }
  }

  ${({ appearance }) => appearances[appearance]};
`;

const SelectOptionWrapper = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey900};
  height: 48px;
  padding: 0 15px;
  gap: 1rem;

  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  :last-child {
    border-radius: 0 0 4px 4px;
  }

  :first-child {
    border-radius: 4px 4px 0 0;
  }

  :hover {
    background-color: var(--primary-500);
    color: ${({ theme }) => theme.colors.white};
  }
`;

export { SelectWrapper, SelectOptionWrapper };
