import moment from 'moment';

const dateFormatLang = {
  '/': {
    fr: 'DD/MM/YYYY',
    en: 'MM/DD/YYYY',
    es: 'DD/MM/YYYY',
  },
  '-': {
    fr: 'DD-MM-YYYY',
    en: 'MM-DD-YYYY',
    es: 'DD-MM-YYYY',
  },
};

function formatDate(date, language = 'fr', separtor = '/') {
  return moment(date).format(dateFormatLang[separtor][language]);
}

export default formatDate;
