import styled, { css } from 'styled-components';
import { devices } from 'tokens';

const titleSize = {
  h1: css`
    font-size: 44px;
    text-transform: uppercase;
  `,
  h2: css`
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
  `,
  h3: css`
    font-size: 28px;
    line-height: 40px;
  `,
  h4: css`
    font-size: 22px;
    line-height: 32px;
  `,
  h5: css`
    font-size: 18px;
    line-height: 22px;

    @media ${devices.mobile}, ${devices.xsmall} {
      font-size: 14px;
      line-height: 17px;
    }
  `,
  h6: css`
    font-size: 16px;
    line-height: 20px;
  `,
  s1: css`
    font-size: 14px;
    line-height: 18px;
  `,
  s2: css`
    font-size: 12px;
    line-height: 14px;
  `,
};

const TitleWrapper = styled.h1`
  font-family: var(--primary-font);
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text500};
  width: auto;
  ${({ size }) => titleSize[size]};
`;

export default TitleWrapper;
