/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { spaces } from 'tokens';

const disabledStyle = css`
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.grey700};
`;

const appearances = {
  primary: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: var(--primary-500);
    border-color: var(--primary-500);
    color: ${({ theme }) => theme.colors.white};

    :hover {
      background-color: var(--primary-400);
      border-color: var(--primary-400);
    }

    :active {
      background: radial-gradient(circle, var(--primary-700) 1%, var(--primary-600) 1%) center;
      border-color: var(--primary-600);
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  secondary: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.text500};
    border-color: ${({ theme }) => theme.colors.text500};
    color: ${({ theme }) => theme.colors.white};

    :hover {
      background-color: ${({ theme }) => theme.colors.text400};
      border-color: ${({ theme }) => theme.colors.text400};
    }

    :active {
      background: ${({ theme }) =>
        `radial-gradient(circle, ${theme.colors.text100} 1%, ${theme.colors.text500} 1%) center`};
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  tertiary: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.white};
    border-color: var(--primary-500);
    color: var(--primary-500);

    :hover {
      border-color: var(--primary-300);
      color: ${({ theme }) => theme.colors.white};
      background-color: var(--primary-300);
    }

    :active {
      background: radial-gradient(circle, var(--primary-100) 1%, var(--primary-200) 1%) center;
      animation: background-gradient 200ms;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.white}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  light: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: var(--primary-100);
    border-color: var(--primary-100);
    color: var(--primary-500);

    :hover {
      border-color: var(--primary-400);
      background-color: var(--primary-200);
    }

    :active {
      background: radial-gradient(circle, var(--primary-200) 1%, var(--primary-300) 1%) center;
      animation: background-gradient 200ms;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.white}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  ghost: css`
    /* STATE NORMAL */
    background-color: transparent;
    border-color: transparent;
    color: var(--primary-500);

    :hover {
      background: ${({ theme }) => theme.colors.grey100};
      border-color: ${({ theme }) => theme.colors.grey100};
    }

    :active {
      animation: background-gradient 1300ms forwards;
      background: ${({ theme }) =>
        `radial-gradient(circle, ${theme.colors.grey200} 1%, ${theme.colors.grey100} 1%) center`};
    }

    /* STATE: DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey300}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey300}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  simple: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text500};
    padding: 0;
    border: none;
    height: auto;
    width: auto;
  `,

  danger: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.danger500};
    border-color: ${({ theme }) => theme.colors.danger500};
    color: ${({ theme }) => theme.colors.white};

    :hover {
      background-color: ${({ theme }) => theme.colors.danger400};
      border-color: ${({ theme }) => theme.colors.danger400};
    }

    :active {
      background: ${({ theme }) =>
        `radial-gradient(circle, ${theme.colors.danger700} 1%, ${theme.colors.danger600} 1%) center`};
      border-color: ${({ theme }) => theme.colors.danger600};
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  dangerInverted: css`
    /* STATE NORMAL */
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.danger500};
    color: ${({ theme }) => theme.colors.danger500};

    :hover {
      background-color: ${({ theme }) => theme.colors.danger500};
      border-color: ${({ theme }) => theme.colors.danger500};
      color: ${({ theme }) => theme.colors.white};
    }

    :active {
      background: ${({ theme }) =>
        `radial-gradient(circle, ${theme.colors.danger700} 1%, ${theme.colors.danger600} 1%) center`};
      border-color: ${({ theme }) => theme.colors.danger600};
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  /* Toggle on click button type styles */
  toggled: css`
    /* STATE NORMAL */
    background-color: var(--primary-100);
    border-color: transparent;
    color: var(--primary-500);

    :hover {
      background-color: var(--primary-200);
    }

    :active {
      background: radial-gradient(circle, var(--primary-200) 1%, var(--primary-100) 1%) center;
      border-color: var(--primary-100);
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,

  untoggled: css`
    /* STATE NORMAL */
    background-color: ${({ theme }) => theme.colors.grey300};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.grey700};

    :hover {
      background-color: var(--primary-200);
      color: var(--primary-400);
    }

    :active {
      background: ${({ theme }) =>
        `radial-gradient(circle, ${theme.colors.grey300} 1%, ${theme.colors.grey200} 1%) center`};
      border-color: ${({ theme }) => theme.colors.grey200};
      animation: background-gradient 200ms forwards;
    }

    /* STATE : DISABLED */
    &&,
    :hover,
    :active {
      background: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
      cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
  `,
};

const buttonSize = {
  default: css`
    height: 40px;
    font-size: 14px;
    font-weight: 500;
  `,

  large: css`
    height: 48px;
    font-size: 16px;
  `,

  small: css`
    height: 32px;
    font-size: 12px;
  `,

  tiny: css`
    height: 24px;
    font-size: 10px;
  `,

  square: css`
    height: 40px;
    width: 40px;
    padding: 0;
  `,
};

const Button = styled.button`
  /* box */
  font-family: var(--primary-font);
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  box-sizing: border-box;

  padding-right: ${spaces[2]};
  padding-left: ${spaces[2]};
  /* text */
  /* other */
  text-decoration: none;

  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ${({ appearance }) => appearances[appearance]};
  ${({ size }) => buttonSize[size]};
  ${({ disabled }) => disabled && disabledStyle};

  .button-loader {
    display: flex;
    align-content: space-between;
    justify-content: center;

    .content {
      margin: 0 20px 0 0;
      display: flex;
    }

    .loader {
      border: 4px solid transparent;
      border-radius: 50%;
      border-width: ${({ size }) => (size === 'tiny' ? '2px' : '4px')};
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.white};
      border-top-color: var(--primary-500);
      border-left-color: var(--primary-500);
      width: ${({ size }) => (size === 'tiny' ? '12px' : '16px')};
      height: ${({ size }) => (size === 'tiny' ? '12px' : '16px')};
      animation: spin 1800ms linear infinite;
    }
  }

  .button__content {
    display: flex;
    align-items: center;

    &--text {
      display: flex;
      width: auto;
      min-width: max-content;
      text-align: left;
      gap: 0.5rem;
    }

    &--icon {
      margin: ${({ iconPosition }) => (iconPosition === 'left' ? '0 12px 0 0' : '0')};
      margin: ${({ iconPosition }) => iconPosition === 'right' && '0 0 0 12px'};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @keyframes background-gradient {
    0% {
      background-size: 100%;
    }

    100% {
      background-size: 14000%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Button;
