/* eslint-disable prettier/prettier, indent */
import styled from 'styled-components';

const DateRangePickerWrapper = styled.div`
  font-family: var(--primary-font);
  width: 300px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey500};
  border-radius: 2px;
  opacity: ${({ openDatePicker }) => (openDatePicker ? '1' : '0')};
  pointer-events: ${({ openDatePicker }) => (openDatePicker ? 'auto' : 'none')};
  position: ${({ position }) => position && 'absolute'};
  top: 0;
  ${({ position, anchorElWidth }) => position && 
    ({
      left: `right: 0px;transform: translateX(${anchorElWidth ? `-${anchorElWidth}px` : '-100%'});`,
      right: `left: 0px;transform: translateX(${anchorElWidth ? `${anchorElWidth}px` : '-100%'});`,
    }[position])}
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey500};
  border-radius: 4px;
  margin: 0 10px;

  .nice-dates-day {
    padding: 2px;
    :before {
      background-color: var(--primary-500);
    }
    &.-today {
      color: var(--primary-500);
    }

    :after {
      border: 2px solid var(--primary-500);
    }
  }

  .date-range-picker {
    &__actions {
      display: flex;
      justify-content: end;
      gap: 28px;
      padding: 16px;
      border-top: 1px solid ${({ theme }) => theme.colors.grey300};
    }
  }
`;

export default DateRangePickerWrapper;
