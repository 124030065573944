import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import { format } from 'date-fns';
import * as locale from 'date-fns/locale';
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates';
import { withTranslation } from 'i18n';
import Button from 'components/Button/Button';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import addBindTo from '../Provider/hocs/addBindTo';

const DateRangePicker = ({
  t,
  anchorEl,
  position,
  openDatePicker,
  theme,
  lang,
  setDateRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  close,
  maximumDate,
  minimumDate,
  displayButtons,
  className,
}) => {
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange({
        start: format(startDate, 'MM-dd-yyyy', { locale: locale[lang] }),
        end: format(endDate, 'MM-dd-yyyy', { locale: locale[lang] }),
      });
    }
  }, [startDate, endDate]);

  const handleCancelButtonClick = () => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
    close();
  };

  const handleUpdateButtonClick = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    close();
  };

  return (
    <DateRangePickerWrapper
      anchorElWidth={anchorEl?.current?.offsetWidth}
      position={position}
      theme={theme}
      openDatePicker={openDatePicker}
      className={className}
    >
      {openDatePicker && (
        <DateRangePickerCalendar
          startDate={displayButtons ? localStartDate : startDate}
          endDate={displayButtons ? localEndDate : endDate}
          focus={focus}
          onStartDateChange={displayButtons ? setLocalStartDate : setStartDate}
          onEndDateChange={displayButtons ? setLocalEndDate : setEndDate}
          onFocusChange={handleFocusChange}
          locale={locale[lang]}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
        />
      )}
      {displayButtons && (
        <div className="date-range-picker__actions">
          <Button appearance="simple" onClick={handleCancelButtonClick}>
            {t('cancel')}
          </Button>
          <Button appearance="primary" onClick={handleUpdateButtonClick}>
            {t('update')}
          </Button>
        </div>
      )}
    </DateRangePickerWrapper>
  );
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  lang: 'fr',
  openDatePicker: false,
  theme: { colors: defaultColors },
  position: null,
  anchorEl: null,
  maximumDate: undefined,
  minimumDate: undefined,
  displayButtons: true,
  className: '',
};

DateRangePicker.propTypes = {
  t: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({
    current: PropTypes.shape({
      ul: PropTypes.shape({}),
      offsetWidth: PropTypes.number,
    }),
  }),
  position: PropTypes.oneOf(['left', 'right']),
  lang: PropTypes.string,
  setDateRange: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  openDatePicker: PropTypes.bool,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  maximumDate: PropTypes.instanceOf(Date),
  minimumDate: PropTypes.instanceOf(Date),
  displayButtons: PropTypes.bool,
  className: PropTypes.string,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(withTranslation('date-range-picker')(DateRangePicker));
