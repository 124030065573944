import React from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import Tippy from '@tippyjs/react';
import Text from 'components/Text';
import addBindTo from '../Provider/hocs/addBindTo';

const Tooltip = ({
  className,
  text,
  position,
  children,
  positionModifier,
  visible,
  duration,
  tooltipTheme,
  interactive,
  disabled,
}) => {
  return (
    <>
      <Tippy
        content={<Text className="font-secondary whitespace-pre-line break-words">{text}</Text>}
        placement={`${position}${positionModifier === 'start' ? '-start' : ''}${
          positionModifier === 'end' ? '-end' : ''
        }`}
        duration={duration}
        visible={visible}
        theme={tooltipTheme}
        interactive={interactive}
        disabled={disabled || !text || (typeof text === 'string' && !text.trim())}
      >
        <div>
          {React.isValidElement(children) ? (
            children
          ) : (
            <span className={className}>{children}</span>
          )}
        </div>
      </Tippy>
    </>
  );
};

Tooltip.defaultProps = {
  className: '',
  theme: { colors: defaultColors },
  positionModifier: '',
  // Setting visible to true or false instead of undefined will
  // allow for using  React's state to fully control the
  // tippy instead of relying on the :hover trigger of its children.
  visible: undefined,
  duration: 100,
  tooltipTheme: 'default',
  interactive: false,
  children: null,
  position: 'top',
  disabled: false,
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  visible: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  tooltipTheme: PropTypes.oneOf(['default', 'light']),
  duration: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number), // [<show in ms>, <hide in ms>]
    PropTypes.number,
  ]),
  positionModifier: PropTypes.oneOf(['end', 'start', '']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Tooltip);
