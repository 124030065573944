import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { parseCookies } from 'nookies';
import getConfig from 'next/config';
import clsx from 'clsx';

// retrieve logo config
const { publicRuntimeConfig } = getConfig();
const { logoBucket } = publicRuntimeConfig;

const Logo = ({ clientId, providerSlug, size = 'normal', align, isLoginPage, children }) => {
  // ready values set for logo
  const cookies = parseCookies();
  const [currentLogoEdition, setCurrentLogoEdition] = useState(clientId || cookies.client_id);
  // wait until cookies are ready
  if (!clientId && !cookies.client_id) return null;

  /** @namespace LogoPath */
  const logoPath = `https://storage.googleapis.com/${logoBucket}/${
    currentLogoEdition === 'default' && providerSlug
      ? `provider_${providerSlug}`
      : `logo_${currentLogoEdition}`
  }.svg`;

  /** @namespace LoginLogoPath */
  const loginLogoPath = logoPath;

  const aligns = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  };

  return (
    <div
      className={clsx(
        'flex h-full items-center gap-0.5 py-2 xl:gap-4',
        { small: 'w-10', normal: 'w-24 xl:w-full xl:max-w-[8rem]', fill: 'w-full' }[size],
        aligns[align] || 'justify-center',
      )}
    >
      <img
        src={isLoginPage ? loginLogoPath : logoPath}
        alt={clientId || cookies.client_id || 'Logo'}
        className="h-full object-contain"
        onError={() => {
          // unless cookies aren't set yet, replace client id by default
          if (cookies.client_id) setCurrentLogoEdition('default');
        }}
      />
      {children}
    </div>
  );
};

Logo.defaultProps = {
  clientId: null,
  providerSlug: null,
  size: 'normal',
  align: 'left',
  isLoginPage: false,
  children: null,
};

Logo.propTypes = {
  clientId: PropTypes.string,
  providerSlug: PropTypes.string,
  children: PropTypes.node,
  isLoginPage: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small', 'fill']),
  align: PropTypes.oneOf(['left', 'right', 'middle']),
};

export default Logo;
