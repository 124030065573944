// eslint-disable-next-line import/no-unresolved
import 'runtime';
import React from 'react';
import PropType from 'prop-types';
import Head from 'next/head';
import useManifestLink from 'components/hooks/useManifestLink';
import { useQuery } from '@apollo/react-hooks';
import GET_URL_COLOR_CSS_QUERY from 'graphql/queries/getUrlColorCss.graphql';

/** @namespace MetaValues */
const MetaValues = {
  title: 'Hyperspread',
  faviconPath: '/static/icons/favicon.ico',
  defaultCss: 'default.css',
};

const Meta = ({ fromLoginPage }) => {
  useManifestLink();
  const defaultCssUrl = `https://storage.googleapis.com/hyperspread-prod-landlord/${MetaValues.defaultCss}`;

  // query style information from back-end
  const { data } = useQuery(GET_URL_COLOR_CSS_QUERY);

  const cssUrl = data?.getUrlColorCss?.url || defaultCssUrl;

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1 viewport-fit=cover" />
      <title> {MetaValues.title} </title>
      <link
        rel="icon"
        type="image/png"
        href={
          process.env.NODE_ENV === 'development'
            ? '/static/icons/dev.favicon.ico' // this will show a red favicon in dev mode
            : MetaValues.faviconPath
        }
      />
      <link rel="apple-touch-icon" href="/static/images/apple-icon-180.png" />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `if (document.domain.match(/hyperspread.com$/)) {
          document.domain = 'hyperspread.com';
        }`,
        }}
      />
      <link rel="apple-touch-icon" href="=/static/images/apple-icon-180.png" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <link rel="stylesheet" type="text/css" href={fromLoginPage ? defaultCssUrl : cssUrl} />
      <link rel="manifest" href="/static/manifest.webmanifest" />
      <style type="text/css">
        {`:root {
          --primary-font: 'Rubik', sans-serif;
          --secondary-font: 'Poppins', sans-serif;

          --brand-50: var(--primary-50);
          --brand-100: var(--primary-100);
          --brand-200: var(--primary-200);
          --brand-300: var(--primary-300);
          --brand-400: var(--primary-400);
          --brand-500: var(--primary-500);
          --brand-600: var(--primary-600);
          --brand-700: var(--primary-700);
          --brand-800: var(--primary-800);
          --brand-900: var(--primary-900);

          --font-title: var(--primary-font);
          --font-body: var(--secondary-font);
        }`}
      </style>
    </Head>
  );
};

Meta.defaultProps = {
  fromLoginPage: false,
};

Meta.propTypes = {
  fromLoginPage: PropType.bool,
};

export default Meta;
