import styled from 'styled-components';
import devices from 'tokens/devices';

const ConsentBannerWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 999999;
  transition-property: transform;
  transition-duration: 0.2s;
  transform: translateY(0px);
  display: flex;
  justify-content: center;
  padding: 32px;

  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.text500};
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .consent-banner {
    display: flex;
    align-items: center;
    position: relative;

    p {
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      max-width: 990px;

      a {
        color: ${({ theme }) => theme.colors.info500};

        &:hover,
        &:active,
        &:visited {
          color: ${({ theme }) => theme.colors.info500};
        }
      }
    }

    button {
      margin-left: 100px;
    }
  }

  @media ${devices.mobile} {
    .consent-banner {
      flex-direction: column;
      button {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
`;

export default ConsentBannerWrapper;
