import { useEffect } from 'react';
import linkTags from 'public/linkTags.js';

const useManifestLink = () => {
  useEffect(() => {
    linkTags.link.forEach((link) => {
      const iconLink = document.createElement('link');

      iconLink.rel = link.rel;
      iconLink.href = link.href;
      if (link.media) iconLink.media = link.media;

      document.head.appendChild(iconLink);

      return () => document.head.removeChild(iconLink);
    });

    const manifestLink = document.createElement('link');

    const metaIcon = document.createElement('meta');

    metaIcon.name = linkTags.meta.name;
    metaIcon.content = linkTags.meta.content;

    manifestLink.rel = 'manifest';
    manifestLink.href = '/static/manifest.webmanifest';

    document.head.appendChild(manifestLink);
    document.head.appendChild(metaIcon);

    return () => {
      document.head.removeChild(metaIcon);
      document.head.removeChild(manifestLink);
    };
  }, []);
};

export default useManifestLink;
