import { useEffect } from 'react';
import { parseCookies } from 'nookies';

const zendeskLanguageMap = {
  fr: 'fr',
  en: 'en-US',
  es: 'es',
};

const useWidgetScript = (url, id, languagePreference) => {
  useEffect(() => {
    const { 'next-i18next': lang } = parseCookies();
    const script = document.createElement('script');

    script.src = url;
    script.id = id;
    if (lang || languagePreference)
      script.onload = () =>
        window?.zE?.('webWidget', 'setLocale', zendeskLanguageMap[lang || languagePreference]);

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, [url, id, languagePreference]);
};

export default useWidgetScript;
