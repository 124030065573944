import { useEffect } from 'react';

const useClarityScript = (clientName, environment) => {
  useEffect(() => {
    if (environment !== 'production' && clientName !== 'default') {
      return;
    }

    const script = document.createElement('script');

    script.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "9k12gh7yr0");`;

    document.head.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      document.head.removeChild(script);
    };
  }, [environment]);
};

export default useClarityScript;
