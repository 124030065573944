import React from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import addBindTo from '../Provider/hocs/addBindTo';
import ButtonWrapper from './ButtonWrapper';

/**
 * Buttons indicate actions on the page that will occur when a user touches them. This button is responsive to screen sizes.
 * We support a very minimal level of variation for the primary button. Custom styling or class names should not be applied to the primary button.
 * It requires text in all cases, but allows for icons as an optional addition.
 * The primary button can also exist as an element within larger molecules and organisms.
 * */
const Button = ({
  className,
  onClick,
  disabled,
  children,
  size,
  appearance,
  icon,
  iconPosition,
  loading,
  theme,
  ...rest
}) => {
  /** @namespace isOneVision @private onevision @fallback return false */
  const isOneVision = true;

  const trueIcon =
    icon && (typeof icon === 'string' ? <i className={`button__content--icon ${icon}`} /> : icon);

  return (
    <ButtonWrapper
      theme={theme}
      type="button"
      className={clsx('button', className, isOneVision && '!normal-case')}
      onClick={!disabled && !loading ? onClick : undefined}
      disabled={disabled}
      size={size}
      appearance={appearance}
      icon={icon}
      iconPosition={iconPosition}
      {...rest}
    >
      {loading ? (
        <div className="button-loader">
          {children && <span className="content">{children}</span>}
          <AiOutlineLoading3Quarters className="animate-spin" />
        </div>
      ) : (
        <span className="button__content">
          {icon && iconPosition !== 'right' && trueIcon}
          <span className="button__content--text">{children}</span>
          {icon && iconPosition === 'right' && trueIcon}
        </span>
      )}
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  onClick: () => {},
  size: 'default',
  appearance: 'primary',
  icon: null,
  iconPosition: '',
  loading: false,
  theme: { colors: defaultColors },
};

Button.propTypes = {
  // className
  className: PropTypes.string,

  // The content of the Button
  children: PropTypes.node,

  // Disable state of the button
  disabled: PropTypes.bool,

  // The function to be called when the button is clicked
  onClick: PropTypes.func,

  // The size of the button
  size: PropTypes.oneOf(['default', 'small', 'large', 'tiny', 'square']),

  // The appearance of the button
  appearance: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'ghost',
    'simple',
    'light',
    'danger',
    'dangerInverted',
    'toggled',
    'untoggled',
  ]),

  // The src of the icon + position
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  iconPosition: PropTypes.string,

  // Loading ?
  loading: PropTypes.bool,

  // colors
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Button);
